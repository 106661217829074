<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">管理挑房</h3>
        <p class="summary">
          根据相关运营规则，可对已上架且不符合运营规则的挑房进行下架处理
        </p>
      </div>
    </div>
    <el-table class="body" :data="list" :row-class-name="tableRowClassName">
      <el-table-column label="直播活动" width="500px">
        <template slot-scope="scope">
          <div class="base-info">
            <el-image
              class="cover"
              fit="cover"
              :src="scope.row.coverImage"
            ></el-image>
            <div class="text">
              <p class="name">{{ scope.row.title }}</p>
              <div class="sub">
                <p v-if="scope.row.labelList || scope.row.purposeList">
                  标签：{{
                    [
                      ...(scope.row.labelList || []),
                      ...(scope.row.purposeList || [])
                    ].toString()
                  }}
                </p>
                <p>创建人：{{ scope.row.inUser }}</p>
                <p>创建时间：{{ scope.row.inDate }}</p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="统计">
        <template slot-scope="scope">
          <p>
            楼盘{{ scope.row.estateCount }}个，浏览{{
              scope.row.viewCount
            }}人，点赞{{ scope.row.praiseCount }}人
          </p>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <p :class="{ red: scope.row.status == 3 }">
            {{ status[scope.row.status] }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope" v-if="scope.row.status != 3">
          <el-button
            style="margin-right: 30px;"
            type="text"
            @click="changeStatus(scope.row.id, scope.row.status)"
            >{{ scope.row.status == 1 ? "下架" : "上架" }}</el-button
          >
          <el-dropdown
            v-if="scope.row.status == 1"
            @visible-change="bool => getQrCode(scope.row.id, bool)"
          >
            <el-button type="text">二维码</el-button>
            <el-dropdown-menu slot="dropdown" disabled>
              <el-dropdown-item class="dropdown-qrcode">
                <el-image class="img" fit="cover" :src="curViewQrCode">
                  <i
                    v-if="!curViewQrCode"
                    slot="error"
                    class="qrcode-loading el-icon-loading"
                  ></i>
                </el-image>
                <p class="tips">
                  请使用微信扫描二维码
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageNumChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getPlatformQrCode } from "@/api/platform";
import { getPickHouseManagePage, changePickHouseStatus } from "@/api/appManage";
export default {
  data() {
    return {
      list: [],
      total: 0,
      status: {
        1: "已发布",
        2: "违规下架",
        3: "草稿"
      },
      pageSizes: [5, 10, 20, 30, 50],
      queryData: {
        pageNum: 1,
        pageSize: 5
      },
      cachedQrCode: {}, //缓存二维码
      curViewQrCode: "" //当前显示二维码图片
    };
  },
  created() {
    this.getPickHouseManagePage();
  },
  methods: {
    async getQrCode(id, show) {
      //获取二维码
      if (!show) return;
      if (this.cachedQrCode[id]) {
        this.curViewQrCode = this.cachedQrCode[id];
        return;
      }
      this.curViewQrCode = "";
      const res = await getPlatformQrCode({ scene: `selectedHouses#0#${id}` });
      if (res) {
        this.curViewQrCode = res;
        this.cachedQrCode[id] = res;
      }
    },
    async changeStatus(id, status) {
      //上架  下架
      const toStatus = status === 1 ? 2 : 1;
      const res = await changePickHouseStatus({ id, status: toStatus });
      if (res) {
        this.getPickHouseManagePage();
      }
    },
    tableRowClassName({ row }) {
      //下架行置灰
      if (row.status === 2) {
        return "gray";
      }
      return "";
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getPickHouseManagePage();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getPickHouseManagePage();
    },
    async getPickHouseManagePage() {
      //获取列表
      const res = await getPickHouseManagePage(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>
<style lang="scss">
.dropdown-qrcode {
  padding: 30px 30px 20px 30px;
  .tips {
    line-height: 20px;
    text-align: center;
    color: $color9;
  }
  .img {
    width: 200px;
    height: 200px;
  }
}
</style>

<style scoped lang="scss">
.base-info {
  display: flex;
  .cover {
    width: 130px;
    min-width: 130px;
    height: 100px;
    border-radius: 5px;
  }
  .text {
    height: 100px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sub {
      color: $color9;
    }
  }
}
.qrcode-loading {
  font-size: 48px;
  color: #ccc;
  position: absolute;
  left: 37%;
  top: 60px;
}
.gray {
  .cell {
    color: $color9;
    p {
      color: $color9;
    }
  }
  .base-info {
    .cover {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.7);
      }
    }
    .text {
      color: $color9;
    }
  }
}
</style>
